// src/components/Navmenu.tsx
import React from 'react';
import { Link } from 'react-router-dom'; // Asegúrate de tener react-router-dom instalado
import './Navmenu.css';
import RenderImg from '../../../renderimg'; 
import logosep from '../../media/logoSEP.png'
import Imgaplicaciones from '../../media/aplicaciones.png'
import ImgX from '../../media/x.png'
import ImgFacebook from '../../media/facebook.png'
const Sidebar: React.FC = () => {
    const rlogosep = [logosep];

    return (
        <div className="navmenu">
            <div className='container-navmenu-logo'>
                <RenderImg images={rlogosep} />
            </div>
            <div className='container-navmenu1'>
                <div className='container-navmenu-int'>
                    <div className='container-navmenu-icon'>             
                        <Link to={'/'} className="nav-item-home">
                            <img src="/alinear-justificar.svg" alt="Menú" className="container-navmenu-icon-int-home"/>
                        </Link>
                        <Link to={'/Admission'} className="nav-item">
                            <img src="/usuarios-alt.svg" alt="Documentos" className="container-navmenu-icon-int" />
                        </Link>
                        <Link to={'/Chat'} className="nav-item">
                            <img src="/auriculares.svg" alt="Chat" className="container-navmenu-icon-int" />
                        </Link>
                        <Link to={'/help'} className="nav-item">
                            <img src="/editar.svg" alt="Ayuda" className="container-navmenu-icon-int" />
                        </Link>
                        <Link to={'/'} className="nav-item">
                            <img src={Imgaplicaciones}  alt="Ayuda" className="container-navmenu-icon-int" />
                        </Link>
                    </div>
                </div>
            <div className='container-navmenu-icon-ext-home'>
                <div>
                    <Link to={'/'} className="social-item">
                        <img src={ImgX} alt="Ayuda" className="container-navmenu-icon-ext" />
                    </Link>
                </div>
                <div>
                    <Link to={'/'} className="social-item">
                        <img src={ImgFacebook} alt="Ayuda" className="container-navmenu-icon-ext" />
                    </Link>
                </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;