// src/components/Bottom.tsx
import React from 'react';
import '../../shared/bottom/BottomHome.css';
import Home from '../../../home';
import ProgressSection from "./ProgressSection";
import HeroinasyHeroes from "./HeroinasyHeroes";
import Convocatorias from "./convovatorias";
import Felicidades from "./Felicidades";
import Footer from "../Footer/Footer";

const Bottom: React.FC = () => {
    return (
        <div className="bottom">
            <ProgressSection
                position="fixed"
                top="170px"
                right="837px"
                width="800px"
            />
            <HeroinasyHeroes
                position="fixed"
                top="170px"
                right="10px"
                width="800px"
                height="152px"
            />
            <HeroinasyHeroes
                position="fixed"
                top="350px"
                right="10px"
                width="800px"
                height="152px"
            />
            <HeroinasyHeroes
                position="fixed"
                top="550px"
                right="10px"
                width="800px"
                height="152px"
            />
            <Convocatorias
                position="fixed"
                top="330px"
                right="1236px"
                width="400px"
            />
            <Felicidades
                position="fixed"
                top="330px"
                right="832px"
                width="400px"
            />
            <Footer/>
            <Convocatorias
                position="fixed"
                top="550px"
                right="1236px"
                width="400px"
            />
            <Felicidades
                position="fixed"
                top="550px"
                right="832px"
                width="400px"
            />
        </div>
    );
};

export default Bottom;