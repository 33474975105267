import React from 'react';
import { useNavigate } from 'react-router-dom'; // Usamos useNavigate para la navegación

const Alerts: React.FC = () => {
    const navigate = useNavigate(); // Usamos el hook useNavigate para navegar entre páginas

    // Lista de alertas sobre ascensos, cursos y otros eventos relevantes
    const alerts = [
        {
            id: 1,
            title: '¡Nuevo ascenso disponible en Carrera Magisterial!',
            description: 'Se han abierto vacantes para ascender al Nivel 3 en Carrera Magisterial. Revisa los requisitos y postúlate ahora.',
            date: '2024-11-17',
        },
        {
            id: 2,
            title: 'Curso de Formación Continua para Maestros',
            description: 'Nuevo curso sobre técnicas de enseñanza innovadoras disponible para todos los maestros interesados. Asegura tu lugar.',
            date: '2024-11-10',
        },
        {
            id: 3,
            title: 'Convocatoria para Evaluación de Desempeño',
            description: 'La evaluación anual de desempeño comenzará pronto. Prepárate para la evaluación que te permitirá avanzar al siguiente nivel.',
            date: '2024-11-01',
        },
        {
            id: 4,
            title: 'Programa de Mentoría para Maestros de Nivel 1',
            description: 'Se abre un nuevo programa de mentoría para maestros que desean mejorar sus habilidades pedagógicas. Inscripciones abiertas.',
            date: '2024-10-25',
        },
        {
            id: 5,
            title: 'Nuevas Vacantes para Nivel 2 en Carrera Magisterial',
            description: 'Las plazas para ascender al Nivel 2 están disponibles. Verifica los requisitos y postúlate.',
            date: '2024-10-15',
        },
    ];

    // Función para regresar al inicio
    const handleGoHome = () => {
        navigate('/home'); // Redirige a la página de inicio
    };

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column' as 'column', // Aseguramos que la dirección sea 'column'
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f4f4f4',
            padding: '20px',
        },
        card: {
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            width: '100%',
            maxWidth: '800px',
        },
        title: {
            textAlign: 'center' as 'center', // Aseguramos que sea 'center'
            fontSize: '24px',
            marginBottom: '20px',
        },
        alertList: {
            listStyleType: 'none',
            padding: '0',
        },
        alertItem: {
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            marginBottom: '10px',
            padding: '15px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        },
        alertTitle: {
            fontSize: '18px',
            fontWeight: 'bold',
            color: '#333',
        },
        alertDescription: {
            fontSize: '16px',
            color: '#666',
        },
        alertDate: {
            fontSize: '14px',
            color: '#999',
            marginTop: '10px',
        },
        homeButton: {
            width: '100%',
            padding: '10px',
            backgroundColor: '#2196F3',
            color: 'white',
            fontSize: '16px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            marginTop: '20px',
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <h1 style={styles.title}>Alertas y Oportunidades</h1>

                <ul style={styles.alertList}>
                    {alerts.map((alert) => (
                        <li key={alert.id} style={styles.alertItem}>
                            <div style={styles.alertTitle}>{alert.title}</div>
                            <div style={styles.alertDescription}>{alert.description}</div>
                            <div style={styles.alertDate}>{alert.date}</div>
                        </li>
                    ))}
                </ul>

                <button onClick={handleGoHome} style={styles.homeButton}>Regresar al Inicio</button>
            </div>
        </div>
    );
};

export default Alerts;
