import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./login";
import Admission from "./Admission";
import Recognition from "./Recognition";
import Promotion from "./Promotion";
import Alerts from "./Alerts";
import Settings from "./Settings";
import Register from "./pages/Register";
import Chat from "./Chat";
import ForgotPassword from "./pages/ForgotPassword";
import BottomHome from "./components/shared/bottom/BottomHome";
import Sidebar from "./components/shared/Sidebar/Sidebar";
import Header from "./components/shared/Header/Header";
import Footer from "./components/shared/Footer/Footer";
import HeaderProfile from "./components/shared/Header/HeaderProfile";
import SidebarProfile from "./components/shared/Sidebar/SidebarProfile";
import ProgressSection from "./components/shared/Profile/ProgressSection";
import HeroinasyHeroes from "./components/shared/Profile/HeroinasyHeroes";
import Convocatorias from "./components/shared/Profile/convovatorias";
import Felicidades from "./components/shared/Profile/Felicidades";
import BottomProfile from "./components/shared/Profile/BottomProfile";
const App: React.FC = () => {
    return (
        <Router>
            <Routes>
            <Route path="/Profile" element={
                <>
                    <HeaderProfile/>
                    <SidebarProfile/>
                    <BottomProfile/>
                    <Footer/>
                    </>
                } />
                {/* Resto de rutas con Header y Sidebar */}
                <Route path="/" element={
                    <>
                        <Header />
                        <Sidebar />
                        <BottomHome />
                        <Footer />
                    </>
                } />
                <Route path="/BottomHome" element={
                    <>
                        <Header />
                        <Sidebar />
                        <BottomHome />
                        <Footer />
                    </>
                } />
                <Route path="/login" element={
                    <>
                        <Header />
                        <Sidebar />
                        <Login />
                        <Footer />
                    </>
                } />
                <Route path="/admission" element={
                    <>
                        <Header />
                        <Sidebar />
                        <Admission />
                        <Footer />
                    </>
                } />
                <Route path="/recognition" element={
                    <>
                        <Header />
                        <Sidebar />
                        <Recognition />
                        <Footer />
                    </>
                } />
                <Route path="/promotion" element={
                    <>
                        <Header />
                        <Sidebar />
                        <Promotion />
                        <Footer />
                    </>
                } />
                <Route path="/alerts" element={
                    <>
                        <Header />
                        <Sidebar />
                        <Alerts />
                        <Footer />
                    </>
                } />
                <Route path="/settings" element={
                    <>
                        <Header />
                        <Sidebar />
                        <Settings />
                        <Footer />
                    </>
                } />
                <Route path="/Register" element={
                    <>
                        <Header />
                        <Sidebar />
                        <Register />
                        <Footer />
                    </>
                } />
                <Route path="/ForgotPassword" element={
                    <>
                        <Header />
                        <Sidebar />
                        <ForgotPassword />
                        <Footer />
                    </>
                } />
                <Route path="/Chat" element={
                    <>
                        <Header />
                        <Sidebar />
                        <Chat />
                        <Footer />
                    </>
                } />
            </Routes>
        </Router>
    );
};

export default App;