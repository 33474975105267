import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import assets16 from '../../components/media/ASSETS-16.png';
import assets17 from '../../components/media/ASSETS-17.png';
import assets18 from '../../components/media/ASSETS-18.png';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', sans-serif;
    color: #4A4A4A;
    margin: 0;  
    height: 100%;  
    overflow: hidden;  
  }
`;

const Container = styled.div`
  width: 100%;  
  height: 100vh;  
  margin: auto;
  margin-bottom: 0;
  padding: 3vh 3vw;  
  display: flex;
  flex-direction: column;  
  overflow: hidden;  
`;

const Title = styled.h1`
  font-size: 1.2dvw;  
  margin: 0;  
  padding-bottom: 1vh;  
`;

const Description = styled.p`
  font-size: 1.7dvh;  
  margin: 0;  
`;

const AccordionContent = styled.div<{ active: boolean }>`
  max-height: ${props => (props.active ? '20vh' : '0')};  
  overflow: hidden;  
  transition: max-height 0.5s ease;  
`;

const AccordionHeader = styled.h3`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin: 0.5rem 0;  
  font-size: 2vh;  
  font-weight: bold;
  color: #4A4A4A;
  position: relative;
    
  &:hover {
    color: #C39D6C; // Color más oscuro al pasar el mouse
  }
  
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px; // Posición de la línea debajo del texto
    width: 100%; // Ancho inicial
    height: 2px; // Altura de la línea
    background-color: #C39D6C; // Color de la línea
    transform: scaleX(0); // Comienza oculto
    transform-origin: left;
    transition: transform 0.3s ease; // Transición suave
  }

  &:hover::after {
    transform: scaleX(1); // Expande la línea al pasar el mouse
  }
`;

const DescriptionToggle = styled.a`
  display: inline-block;
  margin-top: 1rem;
  color: #1D5D4F; // Color inicial
  font-size: 1.8vh; // Tamaño inicial
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease, font-size 0.3s ease; // Transiciones suaves

  &:hover {
    color: #C39D6C; // Color al pasar el mouse
    font-size: 2vh; // Tamaño al pasar el mouse
  }

  &.active {
    color: #1D5D4F; // Color cuando está activo
  }
`;

const ProcessContainer = styled.div`
  display: flex;  
  justify-content: space-between;  
  margin: auto;
  margin-top: 1rem;  
  flex-direction: row;
  flex-wrap: nowrap; 
  overflow: hidden; 
`;

const ProcessItem = styled.div`
  text-align: center;  
  flex: 1; 
  display: flex;
  flex-direction: column; 
  align-items: center; 
  width: 100%; 
  height: auto; 
`;

const ImageContainer = styled.div`
  width: auto;  
  height: auto; 
  overflow: hidden; 
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProcessImage = styled.img<{ isAccordionOpen: boolean; isHovered: boolean }>`
  width: ${props => (props.isAccordionOpen ? '70%' : '100%')};  
  max-height: 100%; 
  min-height: 20px; 
  object-fit: cover; 
  transition: width 0.5s ease, transform 0.3s ease; 
 transform: translateY(${props => ( props.isHovered ? '-5px' : '0')}); 
`;

const ProcessText = styled.p<{ isAccordionOpen: boolean }>`
  font-size: ${props => (props.isAccordionOpen ? '0' : '1.5dvh')};  
  margin-top: 1rem;  
  color: #4A4A4A;  
  transition: font-size 0.5s ease;  
`;

const rassets = [
    { image: assets16, text: 'Admisión' },
    { image: assets17, text: 'Promoción' },
    { image: assets18, text: 'Reconocimiento' },
];

const RenderImg: React.FC<{
    image: string;
    alt: string;
    isAccordionOpen: boolean;
    isHovered: boolean;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
}> = ({ image, alt, isAccordionOpen, isHovered, onMouseEnter, onMouseLeave }) => (
    <ProcessImage 
        src={image} 
        alt={alt} 
        isAccordionOpen={isAccordionOpen} 
        isHovered={isHovered} 
        onMouseEnter={onMouseEnter} 
        onMouseLeave={onMouseLeave} 
    />
);

const BottomLeft: React.FC = () => {
    const [activeAccordion, setActiveAccordion] = useState<string | null>(null);
    const [showDescription, setShowDescription] = useState<boolean>(false);
    const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false); 
    const [hoveredImage, setHoveredImage] = useState<string | null>(null); 
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false); // Nuevo estado para controlar el tamaño de la pantalla

    const toggleAccordion = (id: string) => {
        const newActiveAccordion = activeAccordion === id ? null : id;
        setActiveAccordion(newActiveAccordion);
        setIsAccordionOpen(newActiveAccordion !== null); 
    };

    // Efecto para escuchar cambios en el tamaño de la ventana
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerHeight < 500); // Cambia 500 por la altura que desees
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Llama a la función al montar el componente

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <GlobalStyle />
            <Container>
                <Title>UNIDAD DEL SISTEMA PARA LA CARRERA DE LAS MAESTRAS Y MAESTROS</Title>
                {showDescription && (
                    <Description>
                        Es un órgano administrativo desconcentrado con autonomía técnica, operativa y de gestión, adscrito a la Secretaría de Educación Pública, que tiene a su cargo las atribuciones que le confiere a esa dependencia la Ley General del Sistema para la Carrera de las Maestras y los Maestros, y las que otras leyes establezcan.
                    </Description>
                )}
                <DescriptionToggle onClick={() => setShowDescription(!showDescription)}>
                    {showDescription ? 'Ocultar' : 'Leer más'}
                </DescriptionToggle>
                <h2 style={{fontSize: '1.8dvh', color: '#C39D6C', paddingTop: '10px'}}>PROCESOS</h2>
                <ProcessContainer>
                    {rassets.map((asset, index) => (
                        <ProcessItem key={index}>
                            <ImageContainer>
                                <RenderImg 
                                    image={asset.image} 
                                    alt={asset.text} 
                                    isAccordionOpen={isAccordionOpen} 
                                    isHovered={hoveredImage === asset.image} 
                                    onMouseEnter={() => setHoveredImage(asset.image)} 
                                    onMouseLeave={() => setHoveredImage(null)} 
                                />
                            </ImageContainer>
                            {/* Condicionar la visualización del texto */}
                            {!isSmallScreen && (
                                <ProcessText isAccordionOpen={isAccordionOpen}>
                                    {asset.text}
                                </ProcessText>
                            )}
                        </ProcessItem>
                    ))}
                </ProcessContainer>

                <AccordionHeader onClick={() => toggleAccordion('Misión')}>
                    Misión
                    <FontAwesomeIcon icon={activeAccordion === 'Misión' ? faMinus : faPlus} />
                </AccordionHeader>
                <AccordionContent active={activeAccordion === 'Misión'}>
                    <p style={{fontSize: '1.5dvh', marginTop: '0px'}}>
                      Normar los procesos de selección para la admisión, promoción y reconocimiento de las maestras y los maestros, en sus funciones docente, técnico docente, de asesoría técnica pedagógica, directiva o de supervisión, para que accedan a una carrera justa y equitativa, que favorezca su desarrollo.
                      Ser un órgano que mediante el Sistema para la Carrera de las Maestras y los Maestros se reconozca la contribución a la transformación social de las maestras y los maestros como agentes fundamentales del proceso educativo.
                    </p>
                </AccordionContent>

                < AccordionHeader onClick={() => toggleAccordion('Visión')}>
                    Visión
                    <FontAwesomeIcon icon={activeAccordion === 'Visión' ? faMinus : faPlus} />
                </AccordionHeader>
                <AccordionContent active={activeAccordion === 'Visión'}>
                    <p style={{fontSize: '1.5dvh', marginTop: '0px'}}>
                    Ser la instancia que contribuya al desarrollo integral y máximo logro de aprendizaje de las niñas, niños, adolescentes y jóvenes en un marco de inclusión y de equidad, así como a la revalorización de las maestras y los maestros como profesionales de la educación, contribuyendo a la excelencia de la educación.
                    </p>
                </AccordionContent>

                <AccordionHeader onClick={() => toggleAccordion('Objetivos')}>
                    Objetivos
                    <FontAwesomeIcon icon={activeAccordion === 'Objetivos' ? faMinus : faPlus} />
                </AccordionHeader>
                <AccordionContent active={activeAccordion === 'Objetivos'}>
                    <p style={{fontSize: '1.5dvh', marginTop: '0px'}}>
                    Ejercer las atribuciones que corresponden a la Secretaría de Educación Pública en educación básica y media superior en materia del Sistema para la Carrera de las Maestras y los Maestros.
                    </p>
                </AccordionContent>
            </Container>
        </>
    );
};

export default BottomLeft;