import React from 'react';
import CircularProgress from './CircularProgress';
import './ProgressSection.css';

import icon50 from '../../media/icon-50.png';
import icon51 from '../../media/icon-51.png';
import icon52 from '../../media/icon-52.png';
import icon53 from '../../media/icon-53.png';
import icon54 from '../../media/icon-54.png';
import icon49 from '../../media/icon-49.png';

interface ProgressSectionProps {
  width?: string;
  height?: string;
  position?: 'static' | 'relative' | 'absolute' | 'fixed';
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  className?: string;
}

const ProgressSection: React.FC<ProgressSectionProps> = ({
                                                           width,
                                                           height,
                                                           position,
                                                           top,
                                                           left,
                                                           right,
                                                           bottom,
                                                           className = ''
                                                         }) => {
  const containerStyle = {
    width,
    height,
    position,
    top,
    left,
    right,
    bottom,
  };

  return (
      <div
          className={`progress-container ${className}`}
          style={containerStyle}
      >
        {/* Texto principal arriba */}
        <div className="progress-header">
          <h3 style={{ margin: '8px 0', fontSize: '16px', textAlign: 'center' }}>HEROÍNAS Y HEROES MX</h3>
        </div>

        {/* Imágenes con títulos en una fila */}
        <div className="image-grid" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', gap: '8px' }}>
          <div className="image-item" style={{ textAlign: 'center' }}>
            <img src={icon49} alt="Heroína 1" style={{ maxWidth: '70px', marginBottom: '4px' }} />
            <p style={{ margin: '0', fontSize: '11px' }}>Heroína 1</p>
          </div>
          <div className="image-item" style={{ textAlign: 'center' }}>
            <img src={icon50} alt="Heroína 2" style={{ maxWidth: '70px', marginBottom: '4px' }} />
            <p style={{ margin: '0', fontSize: '11px' }}>Heroína 2</p>
          </div>
          <div className="image-item" style={{ textAlign: 'center' }}>
            <img src={icon51} alt="Heroína 3" style={{ maxWidth: '70px', marginBottom: '4px' }} />
            <p style={{ margin: '0', fontSize: '11px' }}>Heroína 3</p>
          </div>
          <div className="image-item" style={{ textAlign: 'center' }}>
            <img src={icon52} alt="Héroe 1" style={{ maxWidth: '70px', marginBottom: '4px' }} />
            <p style={{ margin: '0', fontSize: '11px' }}>Héroe 1</p>
          </div>
          <div className="image-item" style={{ textAlign: 'center' }}>
            <img src={icon53} alt="Héroe 2" style={{ maxWidth: '70px', marginBottom: '4px' }} />
            <p style={{ margin: '0', fontSize: '11px' }}>Héroe 2</p>
          </div>
          <div className="image-item" style={{ textAlign: 'center' }}>
            <img src={icon54} alt="Héroe 3" style={{ maxWidth: '70px', marginBottom: '4px' }} />
            <p style={{ margin: '0', fontSize: '11px' }}>Héroe 3</p>
          </div>
        </div>
      </div>
  );
};

export default ProgressSection;
