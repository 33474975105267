// src/utils/BottomRightContainer.tsx
import React from 'react';
import styled, { keyframes } from 'styled-components';

// Importar imágenes
import assets01 from '../../components/media/ASSETS-01.png';
import assets02 from '../../components/media/ASSETS-02.png';
import assets03 from '../../components/media/ASSETS-03.png';
import assets04 from '../../components/media/ASSETS-04.png';
import assets05 from '../../components/media/ASSETS-05.png';
import assets06 from '../../components/media/ASSETS-06.png';

// Styled Components
const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px; 
    margin: auto;
    width: 100%; 
    height: 100%;
`;

const InnerContainer = styled.div`
    padding: 10px; /* Padding alrededor del contenido */
    padding-top: 0;
    height: 100%;
`;

const TopContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-height: 50%;
    margin: auto;
`;

const BottomContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-height: 50%;
    margin: auto;
    padding-top: 10px;
`;

const Item = styled.div`
    flex: 1 1 calc(33% - 1%); /* 3 items por fila con espacio reducido */
    margin: 0; /* Eliminación del margen */
    text-align: center;

    @media (max-width: 768px) {
        flex: 1 1 calc(50% - 1%); /* 2 items por fila en pantallas medianas */
    }

    @media (max-width: 480px) {
        flex: 1 1 100%; /* 1 item por fila en pantallas pequeñas */
    }
`;

const ImageContainer = styled.div`
    width: 100%; /* Ocupa todo el ancho del item */
    height: auto; /* Mantiene la relación de aspecto */
`;

const Image = styled.img`
    width: 30%; /* Ajustar el tamaño de la imagen */
    height: auto; /* Mantiene la relación de aspecto */
    transition: transform 0.2s; /* Transición suave */
    
    /* Animación de salto al pasar el mouse */
    &:hover {
        transform: translateY(-5px);
    }
`;

const Text = styled.p`
    margin-top: 1%; 
    font-size: .6vw; /* Tamaño de fuente más pequeño */
    line-height: 1.3; /* Mejora la legibilidad */
    padding: auto; /* Espacio lateral reducido en porcentaje */
    padding-top: 3px;
    font-weight: Bold;
    color: #555555;
    
    @media (max-width: 768px) {
        font-size: 2.5vw; /* Tamaño de fuente más pequeño en pantallas medianas */
    }

    @media (max-width: 480px) {
        font-size: 3.5vw; /* Tamaño de fuente aún más pequeño en pantallas pequeñas */
    }
`;

// Definición de tipos para las propiedades de los activos
interface Asset {
    image: string;
    text: string;
}

const BottomRightContainer: React.FC = () => {
    // Datos de rassets
    const rassets = {
        top: [
            { image: assets06, text: 'Matemáticas para Maestros' },
            { image: assets05, text: 'Premio al Mérito' },
            { image: assets02, text: 'Canal columna vertical TV' },
        ],
        bottom: [
            { image: assets03, text: 'Recursos Tecnológicos' },
            { image: assets01, text: 'Tarjeta MyM' },
            { image: assets04, text: 'Pregúntale a LAZARO' },
        ],
    };

    const RenderImg: React.FC<{ image: string; alt: string }> = ({ image, alt }) => (
        <Image src={image} alt={alt} />
    );

    return (
        <Container>
            <InnerContainer>
                <p style={{color: '#C39D6C', fontWeight: 'Bold', fontSize:'1.8dvh'}}>PROGRAMAS DE VALOR</p>
                <TopContainer>
                    {rassets.top.map((asset: Asset, index: number) => (
                        <Item key={index}>
                            <ImageContainer>
                                <RenderImg image={asset.image} alt={asset.text} />
                            </ImageContainer>
                            <Text>{asset.text}</Text>
                        </Item>
                    ))}
                </TopContainer>
                <BottomContainer>
                    {rassets.bottom.map((asset: Asset, index: number) => (
                        <Item key={index}>
                            <ImageContainer>
                                <RenderImg image={asset.image} alt={asset.text} />
                            </ImageContainer>
                            <Text>{asset.text}</Text>
                        </Item>
                    ))}
                </BottomContainer>
            </InnerContainer>
        </Container>
    );
};

export default BottomRightContainer;    