import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const Container = styled.div`
  max-height: 100%;
  width: 100%;
  overflow: hidden;
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const TabsContainer = styled.div`
  display: flex;
  background-color: #f0f0f0;
  border-radius: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
`;

const Tab = styled.button<{ active: boolean }>`
  flex: 1;
  padding: 10px;
  background: ${props => (props.active ? '#E0CFAE' : 'transparent')};
  color: ${props => (props.active ? '#3B2A1D' : '#333')};
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.9dvh;
  transition: all 0.3s ease;

  &:hover {
    background: ${props => (props.active ? '#D8E3C1' : '#e0e0e0')};
  }
`;

const TabContent = styled.div`
  padding: 1rem;
  border-radius: 15px;
  font-size: 2dvh;
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  justify-content: center;
`;

const Header = styled.div`
  font-size: 2.8dvh;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  color: #3B2A1D;
`;

const DaysContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Day = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% / 6);
  font-size: 1.6dvh;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: # e6f2ff;
  }
`;

const DateCircle = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%; /* Se ajusta al tamaño del contenedor */
  padding-top: 60%; /* Esto crea un cuadrado, ya que el padding-top es igual al width */
  position: relative; /* Necesario para posicionar el contenido dentro del círculo */
  border-radius: 50%;
  background-color: ${props => (props.isActive ? '#007bff' : 'transparent')};
  color: ${props => (props.isActive ? '#fff' : '#333')};
  transition: all 0.3s ease;

  /* El contenido del círculo se posiciona en el centro */
  & > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3dvh; /* Ajusta el tamaño del número según sea necesario */
  }
`;

const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #007bff;
  font-size: 1.5dvh;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: auto;

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

const WeekDay = styled.div`
  font-weight: bold;
  margin-bottom: 0.25rem;
`;

const DateNumber = styled.div`
  font-size: 3dvh;
`;

const Calendar: React.FC = () => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [days, setDays] = useState<Date[]>([]);

  useEffect(() => {
    const calculateDays = () => {
      const newDays: Date[] = [];
      for (let i = -1; i <= 1; i++) {
        const day = new Date(currentDate);
        day.setDate(currentDate.getDate() + i);
        newDays.push(day);
      }
      setDays(newDays);
    };

    calculateDays();
  }, [currentDate]);

  const handlePreviousDay = () => {
    setCurrentDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setDate(prevDate.getDate() - 1);
      return newDate;
    });
  };

  const handleNextDay = () => {
    setCurrentDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setDate(prevDate.getDate() + 1);
      return newDate;
    });
  };

  const handleDayClick = (day: Date) => {
    setSelectedDate(day);
    setCurrentDate(day);
  };

  return (
    <CalendarContainer>
      <Header>{currentDate.toLocaleDateString('es-ES', { month: 'long', year: 'numeric' })}</Header>
      <DaysContainer>
        <Button onClick={handlePreviousDay} disabled={days[0]?.getTime() === selectedDate.getTime()}>
          <ChevronLeft />
        </Button>
        {days.map((day, index) => (
          <Day
            key={index}
            onClick={() => handleDayClick(day)}
          >
            <WeekDay>{day.toLocaleDateString('es-ES', { weekday: 'short' })}</WeekDay>
            <DateCircle isActive={day.toDateString() === selectedDate.toDateString()}>
              <DateNumber>{day.getDate()}</DateNumber>
            </DateCircle>
          </Day>
        ))}
        <Button onClick={handleNextDay} disabled={days[2]?.getTime() === selectedDate.getTime()}>
          <ChevronRight />
        </Button>
      </DaysContainer>
    </CalendarContainer>
  );
};

const TabbedCalendar: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const renderContent = () => {
    const ContentWrapper = styled.div`
      text-align: center;
    `;

    switch (activeTab) {
      case 0:
        return <Calendar />;
      case 1:
        return <ContentWrapper>Próximas citas: Aquí puedes agregar tus próximas citas.</ContentWrapper>;
      case 2:
        return <ContentWrapper>Curso: Información sobre el curso. Y más detalles</ContentWrapper>;
      default:
 return null;
    }
  };

  return (
    <Container>
      <OuterContainer>
        <TabsContainer>
          <Tab active={activeTab === 0} onClick={() => setActiveTab(0)}>Calendario</Tab>
          <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>Próximas citas</Tab>
          <Tab active={activeTab === 2} onClick={() => setActiveTab(2)}>Curso</Tab>
        </TabsContainer>
        <TabContent>
          {renderContent()}
        </TabContent>
      </OuterContainer>
    </Container>
  );
};

export default TabbedCalendar;