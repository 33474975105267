import React from 'react';
import CircularProgress from './CircularProgress';
import './ProgressSection.css';

interface ProgressSectionProps {
  width?: string;
  height?: string;
  position?: 'static' | 'relative' | 'absolute' | 'fixed';
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  className?: string;
}

const ProgressSection: React.FC<ProgressSectionProps> = ({
  width,
  height,
  position,
  top,
  left,
  right,
  bottom,
  className = ''
}) => {
  const containerStyle = {
    width,
    height,
    position,
    top,
    left,
    right,
    bottom,
  };

  return (
    <div 
    className={`progress-container ${className}`}
    style={containerStyle}
  >
    <div className="content-wrapper">
      {/* Header a la izquierda */}
      <div className="progress-header">
        <div className="header-content">
          <i className="fas fa-user-edit"></i>
          <div className="header-text">
            <h3>DATOS PERSONALES Y PROFESIONALES</h3>
            <p>Al completar tu información obtendrás tu insignia Perfil Actualizado</p>
          </div>
        </div>
      </div>

      {/* Círculos a la derecha */}
      <div className="progress-circles">
        <div className="progress-item">
          <CircularProgress percentage={100} color="#FFD700" />
          <span className="label">INFORMACIÓN</span>
        </div>

        <div className="progress-item">
          <CircularProgress percentage={100} color="#00BFA5" />
          <span className="label">DOCUMENTACIÓN</span>
        </div>

        <div className="progress-item">
          <CircularProgress percentage={100} color="#9C27B0" />
          <span className="label">FORMACIÓN</span>
        </div>

        <div className="progress-item">
          <CircularProgress percentage={100} color="#FF7043" />
          <span className="label">EVALUACIÓN</span>
        </div>
      </div>
    </div>
  </div>
);
};

export default ProgressSection;