import React, { useState, useEffect } from 'react';
import RenderImg from '../../renderimg';
import assets10 from '../../components/media/ASSETS-10.png';
import laImage from '../../components/media/la.png';
import { Link, useNavigate } from 'react-router-dom';
import ChatbotLazaro01 from '../Internal/ChatbotLazaro01'; // Import the Chatbot component

const styles = `
    .containerh-bottom-center {
        width: 100%;
        height: 100%; /* Altura total de la ventana */
        display: flex;
        flex-direction: column; /* Cambiado a columna */
        align-items: center;
        position: relative;
        box-sizing: border-box;
        overflow: hidden; /* Evitar desbordamiento */
    }

    .content {
        width: 100%;
        height: 50%; /* 50% de la altura de la ventana */
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top-right-radius: clamp(30px, 6vw, 60px);
        border-bottom-left-radius: 0;
        flex: 1;
        gap: clamp(8px, 2vw, 15px);
    }

    .containerh-bottom-center-01 {
        flex: 0 0 40%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: clamp(8px, 2vw, 15px);
        height: 100%; /* 100% de la altura del contenedor */
        padding-left: 15px;


    }

    .containerh-bottom-center-02 {
        flex: 0 0 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #c6d8b4;
        border-bottom-left-radius: clamp(30px, 6vw, 60px);
        border-top-right-radius: clamp(30px, 6vw, 60px);
        position: relative;
        overflow: hidden;
        padding: 0;
        margin: 0;
        height: 100%; /* 100% de la altura del contenedor */
    }

    .image-container {
        width: 60%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    .image-container img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }

    .acceso-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        padding: auto;
    }

    .btn-iniciar, .btn-registrar {
        width: clamp(90%, 65%, 30%);
        padding: clamp(4px, 1vh, 10px) clamp(8px, 1.2vw, 20px);
        border-radius: clamp(20px, 4vw, 40px);
        font-size: clamp(10px, 1vw, 16px);
        white-space: nowrap;
        transition: all 0.3s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        height: clamp(40%, 3.5vh, 35px);
        border: 1px solid #2B4541;
        cursor: pointer;
    }

    .btn-iniciar {
        background-color: #2B4541;
        color: #fff;
    }

    .btn-registrar {
        background-color: #fff;
        color: #000;
    }

    .btn-iniciar:hover {
        background-color: #DCF8C6; /* Cambia el color de fondo */
        color: #2B4541; /* Cambia el color del texto a un color oscuro */
        transform: scale(1.05); /* Aumenta el tamaño ligeramente */
    }

    .btn-registrar:hover {
        background-color: #DCF8C6; /* Cambia el color de fondo */
        color: #2B4541; /* Cambia el color del texto a un color oscuro */
        transform: scale(1.05); /* Aumenta el tamaño ligeramente */
    }
        

    .ForgotPassword {
        color: #2B4541;
        text-decoration: underline;
        margin: 10px 0; /* Añadir margen para separar del botón */
        font-size: clamp(8px, 0.8vw, 14px); /* Tamaño de letra más pequeño */
        text-align: center; /* Centrar el texto */
    }

    @media (max-width: 768px) {
        .content {
            flex-direction: column;
            gap: clamp(15px, 3vh, 30px);
            padding: 0 clamp(10px, 2vw, 20px);
        }

        .containerh-bottom-center-01,
        .containerh-bottom-center-02 {
            flex: 0 0 auto;
            width: 100%;
        }
    }
`;

const BottomHomeCenter: React.FC = () => {
    const [imagePosition, setImagePosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const maxMovement = 10;
    const navigate = useNavigate();

    useEffect(() => {
        const calculateImagePosition = (e: MouseEvent) => {
            const centerX = window.innerWidth / 2;
            const relativeCenterX = (e.clientX - centerX) / centerX;
            const centerY = window.innerHeight / 2;
            const distanceY = (e.clientY - centerY) / centerY;
            const newX = relativeCenterX * maxMovement;
            const newY = distanceY * maxMovement;

            setImagePosition({
                x: newX,
                y: newY
            });
        };

        const handleMouseLeave = () => {
            window.addEventListener('mousemove', calculateImagePosition);
        };

        const handleMouseEnter = () => {
            window.removeEventListener('mousemove', calculateImagePosition);
        };

        window.addEventListener('mousemove', calculateImagePosition);
        window.addEventListener('mouseleave', handleMouseLeave);
        window.addEventListener('mouseenter', handleMouseEnter);

        return () => {
            window.removeEventListener('mousemove', calculateImagePosition);
            window.removeEventListener('mouseleave', handleMouseLeave);
            window.removeEventListener('mouseenter', handleMouseEnter);
        };
    }, []);

    const rassets10 = [assets10];
    const rlaImage = [laImage];

    return (
        <div className='containerh-bottom-center'>
            <div className='content'>
                <div className='containerh-bottom-center-01'>
                    <div className="acceso-section">
                        <button onClick={() => navigate('/login')} className="btn-iniciar">
                            Iniciar sesión
                        </button>
                        <button onClick={() => navigate('/admission')} className="btn-registrar">
                            Registrarme
                        </button>
                        <Link to="/ForgotPassword" className="ForgotPassword">
                            Olvidé mi contraseña
                        </Link>
                    </div>
                </div>
                <div className='containerh-bottom-center-02'>
                    <div className='image-container'>
                        <RenderImg images={rassets10} />
                        <div style={{ 
                            transform: `translate(calc(-50% + ${imagePosition.x}px), calc(-50% + ${imagePosition.y}px))`,
                            position: 'absolute',
                            top: '35%',
                            left: '50%',
                            transition: 'transform .1s cubic-bezier(0.4, 0, 0.2, 1)',
                        }}>
                            <RenderImg images={rlaImage} />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '55%' }}>
                <ChatbotLazaro01 /> {/* Add the Chatbot component here */}
            </div>
            <style>{styles}</style>
        </div>
    );
};

export default BottomHomeCenter;