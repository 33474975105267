// src/components/Bottom.tsx
import React from 'react';
import '../../shared/bottom/BottomHome.css';
import Home from '../../../home';

const Bottom: React.FC = () => {
    return (
        <div className="bottom">
            <Home />
        </div>
    );
};

export default Bottom;