import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/shared/Header/Header';
import Footer from '../components/shared/Footer/Footer';
import './Register.css';

interface RegisterFormData {
    nombre: string;
    apellidos: string;
    curp: string;
    email: string;
    telefono: string;
    password: string;
    confirmPassword: string;
}

const Register: React.FC = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState<RegisterFormData>({
        nombre: '',
        apellidos: '',
        curp: '',
        email: '',
        telefono: '',
        password: '',
        confirmPassword: ''
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        // Validaciones
        if (formData.password !== formData.confirmPassword) {
            alert('Las contraseñas no coinciden');
            return;
        }

        if (formData.password.length < 6) {
            alert('La contraseña debe tener al menos 6 caracteres');
            return;
        }

        // Obtener usuarios existentes
        const users = JSON.parse(localStorage.getItem('users') || '[]');

        // Verificar si el usuario ya existe
        if (users.some((user: RegisterFormData) => user.curp === formData.curp)) {
            alert('Ya existe un usuario con este CURP');
            return;
        }

        if (users.some((user: RegisterFormData) => user.email === formData.email)) {
            alert('Ya existe un usuario con este correo electrónico');
            return;
        }

        // Crear nuevo usuario
        const newUser = {
            ...formData,
            id: Date.now(),
            fechaRegistro: new Date().toISOString()
        };

        // Guardar en localStorage
        users.push(newUser);
        localStorage.setItem('users', JSON.stringify(users));
        localStorage.setItem('lastRegisteredUser', JSON.stringify(newUser));

        alert('Registro exitoso');
        navigate('/welcome');
    };

    return (
        <div className="page-container">
            <Header />
            <main className="main-content">
                <div className="form-container">
                    <h1>Registro de Usuario</h1>
                    <form onSubmit={handleSubmit} className="register-form">
                        <input
                            type="text"
                            name="nombre"
                            value={formData.nombre}
                            onChange={handleChange}
                            placeholder="Nombre(s)"
                            required
                        />
                        <input
                            type="text"
                            name="apellidos"
                            value={formData.apellidos}
                            onChange={handleChange}
                            placeholder="Apellidos"
                            required
                        />
                        <input
                            type="text"
                            name="curp"
                            value={formData.curp}
                            onChange={handleChange}
                            placeholder="CURP"
                            required
                        />
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Correo Electrónico"
                            required
                        />
                        <input
                            type="tel"
                            name="telefono"
                            value={formData.telefono}
                            onChange={handleChange}
                            placeholder="Teléfono"
                            required
                        />
                        <input
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            placeholder="Contraseña"
                            required
                        />
                        <input
                            type="password"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            placeholder="Confirmar Contraseña"
                            required
                        />
                        <div className="button-container">
                            <button type="submit" className="primary-button">
                                Registrarse
                            </button>
                            <button
                                type="button"
                                className="secondary-button"
                                onClick={() => navigate('/login')}
                            >
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default Register;