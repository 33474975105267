import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import laImage from '../../components/media/la.png';

// Styled components
const MainContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    background-color: #efeae2;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    padding: 10px;
    box-sizing: border-box;
`;

const ChatbotContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    margin: auto;
    height: 100%;
    background-color: #f0f2f5;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.06), 0 2px 5px 0 rgba(0,0,0,.2);
    border-radius: 10px;
    overflow: hidden;
`;

const ChatHeader = styled.div`
    background-color: #1D5D4F;
    color: white;
    padding: 3px 16px;
    display: flex;
    align-items: center;
`;

const BotAvatar = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;

    @media (max-width: 768px) {
        width: 32px;
        height: 32px;
        margin-right: 10px;
    }
`;

const BotImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const BotName = styled.div`
    font-size: 16px;
    font-weight: 500;

    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const Messages = styled.div`
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    background-color: #efeae2;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media (max-width: 768px) {
        padding: 10px;
    }

    /* Estilos para la barra de desplazamiento */
    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(29, 93, 79, 0.5);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #1D5D4F;
    }
`;

const MessageWrapper = styled.div<{ role: string }>`
    display: flex;
    justify-content: ${props => props.role === 'user' ? 'flex-end' : 'flex-start'};
    margin-bottom: 4px;
    align-items: flex-end;
`;

const Message = styled.div<{ role: string }>`
    max-width: 75%;
    padding: 8px 10px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 1.4;
    background-color: ${props => props.role === 'user' ? '#DCF8C6' : '#1D5D4F'};
    color: ${props => props.role === 'assistant' ? 'white' : 'black'};
    box-shadow: 0 1px 0.5px rgba(0,0,0,0.13);
    position: relative;
    word-wrap: break-word;

    @media (max-width: 768px) {
        max-width: 85%;
        font-size: 13px;
    }

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        ${props => props.role === 'user' ? 'right: -8px;' : 'left: -8px;'}
        width: 0;
        height: 0;
        border-top: 8px solid ${props => props.role === 'user' ? '#DCF8C6' : '#1D5D4F'};
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        transform: ${props => props.role === 'user' ? 'rotate(45deg)' : 'rotate(-45deg)'};
    }
`;

const MessageTime = styled.span<{ role: string }>`
    font-size: 11px;
    color: ${props => props.role === 'assistant' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.45)'};
    float: right;
    margin-left: 4px;
    margin-top: 2px;
`;

const InputBox = styled.form`
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #f0f2f5;

    @media (max-width: 768px) {
        padding: 8px;
    }
`;

const InputWrapper = styled.div`
    flex: 1;
    background-color: white;
    border-radius: 21px;
    padding: 9px 12px;
    margin-right: 8px;
    box-shadow: 0 1px 0.5px rgba(0,0,0,0.13);

    @media (max-width: 768px) {
        padding: 6px 10px;
    }
`;

const TextArea = styled.textarea`
    width: 100%;
    border: none;
    resize: none;
    font-size: 15px;
    font-family: inherit;
    line-height: 20px;
    min-height: 20px;
    max-height: 100px;

    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 18px;
    }

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: #8696a0;
    }
`;

const SendButton = styled.button`
    background-color: #1D5D4F;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;

    @media (max-width: 768px) {
        width: 36px;
        height: 36px;
    }

    &:hover {
        background-color: #00A884;
    }

    &:disabled {
        background-color: #D1D7DB;
        cursor: not-allowed;
    }

    svg {
        width: 20px;
        height: 20px;

        @media (max-width: 768px) {
            width: 18px;
            height: 18px;
        }
    }
`;

const DateSeparator = styled.div`
    text-align: center;
    margin: 12px 0;
    position: relative;

    span {
        background-color: #E1F2FB;
        padding: 5px 12px;
        border-radius: 7px;
        font-size: 12.5px;
        color: #54656F;
        box-shadow: 0 1px 0.5px rgba(0,0,0,0.13);
    }

    @media (max-width: 768px) {
        margin: 8px 0;

        span {
            font-size: 11px;
            padding: 4px 10px;
        }
    }
`;

const SendIcon = () => (
    <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M1.101,21.757L23.8,12.028L1.101,2.3l0.011,7.912l13.623,1.816L1.112,13.845 L1.101,21.757z"></path>
    </svg>
);

const popAnimation = keyframes`
    0% { transform: scale(0.8); opacity: 0.7; }
    50% { transform: scale(1.1); opacity: 1; }
    100% { transform: scale(1); opacity: 1; }
`;

const MessageBotAvatar = styled(BotAvatar)`
    width: 30px;
    height: 30px;
    margin-right: 8px;
    opacity: 0.7;
    transition: all 0.3s ease;

    &:hover {
        animation: ${popAnimation} 0.3s ease-in-out;
        opacity: 1;
    }

    @media (max-width: 768px) {
        width: 24px;
        height: 24px;
        margin-right: 6px;
    }
`;

const OPENAI_API_URL = 'https://api.openai.com/v1/chat/completions';
const OPENAI_API_KEY = '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';
const LLM_STUDIO_API_URL = 'https://lazaro.mexzatlal.com.mx/v1/chat/completions';

interface Message {
    role: 'user' | 'assistant' | 'error';
    content: string;
    time: string;
    timestamp: number;
}

const ChatbotLazaro01: React.FC = () => {
    const [messages, setMessages] = useState<Message[]>(() => {
        const savedMessages = localStorage.getItem('chatMessages');
        return savedMessages ? JSON.parse(savedMessages) : [{
            role: 'assistant',
            content: '¡Hola! Soy Lázaro, tu asistente virtual. ¿En qué puedo ayudarte hoy?',
            time: new Date().toLocaleTimeString('es-ES', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            }),
            timestamp: new Date().getTime()
        }];
    });

    const [inputValue, setInputValue] = useState<string>('');
    const messagesEndRef = useRef<HTMLDivElement | null>(null);

    const [conversationHistory, setConversationHistory] = useState<Message[]>(() => {
        const savedHistory = localStorage.getItem('conversationHistory');
        return savedHistory ? JSON.parse(savedHistory) : [
            { role: "system", content: "Eres Lázaro, un asistente virtual amigable y profesional de la Unidad del Sistema para la Carrera de las Maestras y los Maestros (USICAMM) en México. Proporciona respuestas claras, breves y concisas sobre las funciones y atribuciones de USICAMM en educación básica y media superior. Usa solo información oficial y actualizada de USICAMM. Si no tienes información sobre algo, indícalo claramente." },
            { 
                role: "assistant", 
                content: "¡Hola! Soy Lázaro, tu asistente virtual. ¿En qué puedo ayudarte hoy con respecto a USICAMM?"
            }
        ];
    });

    useEffect(() => {
        localStorage.setItem('chatMessages', JSON.stringify(messages));
    }, [messages]);

    useEffect(() => {
        localStorage.setItem('conversationHistory', JSON.stringify([
            { role: "system", content: "Eres Lázaro, un asistente virtual amigable y profesional de la Unidad del Sistema para la Carrera de las Maestras y los Maestros (USICAMM) en México. Proporciona respuestas claras, breves y concisas sobre las funciones y atribuciones de USICAMM en educación básica y media superior. Usa solo información oficial y actualizada de USICAMM. Si no tienes información sobre algo, indícalo claramente." },
            ...conversationHistory.slice(1)
        ]));
    }, [conversationHistory]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const getCurrentTime = (): string => {
        return new Date().toLocaleTimeString('es-ES', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });
    };

    const formatDate = (timestamp: number): string => {
        const date = new Date(timestamp);
        return date.toLocaleDateString('es-ES', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    const groupMessagesByDate = (messages: Message[]) => {
        const groups: { [key: string]: Message[] } = {};
        messages.forEach(msg => {
            const date = formatDate(msg.timestamp);
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(msg);
        });
        return groups;
    };

    const handleSendMessage = async () => {
        if (!inputValue.trim()) return;

        const userMessage: Message = { 
            role: 'user', 
            content: inputValue,
            time: getCurrentTime(),
            timestamp: new Date().getTime()
        };

        setMessages(prevMessages => [...prevMessages, userMessage]);
        setConversationHistory(prevHistory => [...prevHistory, userMessage]);
        setInputValue('');

        try {
            const llmStudioResponse = await axios.post(LLM_STUDIO_API_URL, {
                model: 'llama3.2',
                messages: [...conversationHistory, userMessage],
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const botMessage: Message = {
                role: 'assistant',
                content: llmStudioResponse.data.choices[0].message.content,
                time: getCurrentTime(),
                timestamp: new Date().getTime()
            };

            setMessages(prevMessages => [...prevMessages, botMessage]);
            setConversationHistory(prevHistory => [...prevHistory, botMessage]);

        } catch (llmStudioError) {
            console.error('Error al comunicarse con LLM Studio:', llmStudioError);
            
            try {
                const openaiResponse = await axios.post(OPENAI_API_URL, {
                    model: 'gpt-3.5-turbo',
                    messages: [...conversationHistory, userMessage],
                }, {
                    headers: {
                        'Authorization': `Bearer ${OPENAI_API_KEY}`,
                        'Content-Type': 'application/json',
                    }
                });

                const botMessage: Message = {
                    role: 'assistant',
                    content: openaiResponse.data.choices[0].message.content,
                    time: getCurrentTime(),
                    timestamp: new Date().getTime()
                };

                setMessages(prevMessages => [...prevMessages, botMessage]);
                setConversationHistory(prevHistory => [...prevHistory, botMessage]);

            } catch (openaiError) {
                console.error('Error al comunicarse con OpenAI:', openaiError);
                addErrorMessage('Error en la comunicación con los servidores de IA.');
            }
        }
    };

    const addErrorMessage = (text: string) => {
        const errorMessage: Message = { 
            role: 'error', 
            content: text,
            time: getCurrentTime(),
            timestamp: new Date().getTime()
        };
        setMessages(prevMessages => [...prevMessages, errorMessage]);
    };

    return (
        <MainContainer>
            <ChatbotContainer>
                <ChatHeader>
                    <BotAvatar>
                        <BotImage src={laImage} alt="Lázaro" />
                    </BotAvatar>
                    <BotName>Asistente Lázaro</BotName>
                </ChatHeader>
                <Messages>
                    {Object.entries(groupMessagesByDate(messages)).map(([date, dateMessages]) => (
                        <React.Fragment key={date}>
                            <DateSeparator>
                                <span>{date}</span>
                            </DateSeparator>
                            {dateMessages.map((msg, index) => (
                                <MessageWrapper key={index} role={msg.role}>
                                    {msg.role === 'assistant' && (
                                        <MessageBotAvatar>
                                            <BotImage src={laImage} alt="Lázaro" />
                                        </MessageBotAvatar>
                                    )}
                                    <Message role={msg.role}>
                                        {msg.content}
                                        <MessageTime role={msg.role}>{msg.time}</MessageTime>
                                    </Message>
                                </MessageWrapper>
                            ))}
                        </React.Fragment>
                    ))}
                    <div ref={messagesEndRef} />
                </Messages>
                <InputBox onSubmit={(e) => { e.preventDefault(); handleSendMessage(); }}>
                    <InputWrapper>
                        <TextArea
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    handleSendMessage();
                                }
                            }}
                            placeholder="Pregúntale a Lázaro"
                            rows={1}
                        />
                    </InputWrapper>
                    <SendButton 
                        type="submit" 
                        disabled={!inputValue.trim()}
                    >
                        <SendIcon />
                    </SendButton>
                </InputBox>
            </ChatbotContainer>
        </MainContainer>
    );
};

export default ChatbotLazaro01;

