import React from 'react';
import { useNavigate } from 'react-router-dom'; // Usamos useNavigate para navegación

interface Recognition {
    title: string;
    description: string;
    date: string;
}

const Recognitions: React.FC = () => {
    const navigate = useNavigate(); // Usamos el hook useNavigate

    // Ejemplo de lista de reconocimientos
    const recognitions: Recognition[] = [
        { title: 'Reconocimiento por excelencia académica', description: 'Premio otorgado por logros excepcionales en la enseñanza.', date: '2023-05-15' },
        { title: 'Reconocimiento por innovación educativa', description: 'Distinción por el uso creativo de tecnologías en el aula.', date: '2022-11-30' },
        { title: 'Reconocimiento a la trayectoria docente', description: 'Premio por 10 años de experiencia en la educación.', date: '2021-08-25' },
        // Puedes agregar más reconocimientos según sea necesario
    ];

    const handleGoHome = () => {
        navigate('/home'); // Redirige a la página de inicio (Home)
    };

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column' as 'column', // Aseguramos que la dirección es 'column'
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f4f4f4',
            padding: '20px',
        },
        card: {
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            width: '100%',
            maxWidth: '800px',
            textAlign: 'left' as 'left', // Aseguramos que el valor sea válido
        },
        title: {
            textAlign: 'center' as 'center', // Aseguramos que sea 'center'
            fontSize: '24px',
            marginBottom: '20px'
        },
        recognitionItem: {
            padding: '15px',
            marginBottom: '15px',
            borderBottom: '1px solid #ccc',
        },
        recognitionTitle: {
            fontSize: '18px',
            fontWeight: 'bold',
        },
        recognitionDescription: {
            fontSize: '16px',
            color: '#555',
        },
        recognitionDate: {
            fontSize: '14px',
            color: '#888',
            marginTop: '10px',
        },
        homeButton: {
            width: '100%',
            padding: '10px',
            backgroundColor: '#2196F3',
            color: 'white',
            fontSize: '16px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            marginTop: '20px',
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <h1 style={styles.title}>Reconocimientos</h1>
                <div>
                    {recognitions.length === 0 ? (
                        <p>No se han registrado reconocimientos aún.</p>
                    ) : (
                        recognitions.map((recognition, index) => (
                            <div key={index} style={styles.recognitionItem}>
                                <h2 style={styles.recognitionTitle}>{recognition.title}</h2>
                                <p style={styles.recognitionDescription}>{recognition.description}</p>
                                <p style={styles.recognitionDate}>Fecha: {recognition.date}</p>
                            </div>
                        ))
                    )}
                </div>
                <button onClick={handleGoHome} style={styles.homeButton}>Regresar al Inicio</button> {/* Botón para regresar al home */}
            </div>
        </div>
    );
};

export default Recognitions;
