import React, { useState } from 'react';
import { sendMessage } from './services/chatService';
import './Chat.css';

const Chat: React.FC = () => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState<Array<{text: string, isUser: boolean}>>([
    {
      text: '¡Hola! Soy Lázaro, tu asistente virtual. ¿En qué puedo ayudarte hoy?',
      isUser: false
    }
  ]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim() || isLoading) return;

    const userMessage = input.trim();
    setInput('');
    setMessages(prev => [...prev, { text: userMessage, isUser: true }]);
    setIsLoading(true);

    try {
      const response = await sendMessage(userMessage);
      setMessages(prev => [...prev, { text: response, isUser: false }]);
    } catch (error) {
      setMessages(prev => [...prev, { 
        text: 'Lo siento, hubo un error. Por favor, intenta de nuevo.',
        isUser: false 
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="chatbot">
      <div className="chat-container">
        <div className="chat-messages">
          {messages.map((msg, index) => (
            <div key={index} className={`message ${msg.isUser ? 'user' : 'bot'}`}>
              {!msg.isUser && (
                <img src="/lazaro.gif" alt="Lázaro" className="robot-avatar" />
              )}
              <div className="message-content">{msg.text}</div>
            </div>
          ))}
          {isLoading && (
            <div className="message bot">
              <img src="/lazaro.gif" alt="Lázaro" className="robot-avatar" />
              <div className="message-content typing">
                <span>.</span><span>.</span><span>.</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <form onSubmit={handleSubmit} className="chat-input-container">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Pregúntale a Lázaro"
          className="chat-input"
          disabled={isLoading}
        />
        <button 
          type="submit" 
          className="send-button"
          disabled={isLoading}
        >
          Enviar
        </button>
      </form>
    </div>
  );
};

export default Chat; 