import React from 'react';
import './Header.css';
import { FaSearch } from 'react-icons/fa';
import formmex from '../../media/formmex-logo.png';
import blanck from '../../media/blanck-space.png';
import medalCheck from '../../media/dashmm-19.png'; 
import medalhat from '../../media/dashmm-20.png';
import medal5 from '../../media/dashmm-21.png'; 
import medalnominado from '../../media/dashmm-22.png';  
import medaldoc from '../../media/dashmm-23.png'; 
import proxMeta from '../../media/dashmm-25.png'; 
const HeaderProfile: React.FC = () => {

  return (
    <div className="header-profile">

      <div className="header-left">
        <img src= { blanck }  alt="FORMMEX" className="formmex-logo" />
        <img src= { blanck }  alt="FORMMEX" className="formmex-logo" />
        <img src= { blanck }  alt="FORMMEX" className="formmex-logo" />
        <img src= { blanck }  alt="FORMMEX" className="formmex-logo" />
      </div>

      {/* Logo y título */}
      <div className="header-left">
        <div className="logo-stack">
          <img src={formmex} alt="FORMMEX" className="formmex-logo" />
          <div className="user-title">
            <h1 className="title-maestro">MAESTRO</h1>
            <h2 className="name">Gerardo Miranda Ortiz</h2>
            <p className="education-level">EDUCACIÓN MEDIA SUPERIOR</p>
          </div>
        </div>
      </div>

{/* Logros */}
<div className="achievements">
  <div className="achievement-item">
    <div className="medal-container">
      <img src={medalCheck} alt="Perfil 100% actualizado" />
      <span>Perfil 100% actualizado</span>
    </div>
  </div>
  <div className="achievement-item">
    <div className="medal-container">
      <img src={medalhat} alt="Competencias Actualizadas" />
      <span>Competencias Actualizadas</span>
    </div>
  </div>
  <div className="achievement-item">
    <div className="medal-container">
      <img src={medal5} alt="5 Años de Servicio" />
      <span>5 Años de Servicio</span>
    </div>
  </div>
  <div className="achievement-item">
    <div className="medal-container">
      <img src={medalnominado} alt="Nominado" />
      <span>Nominado</span>
    </div>
  </div>
  <div className="achievement-item">
    <div className="medal-container">
      <img src={medaldoc} alt="Documentación Verificada" />
      <span>Documentación Verificada</span>
    </div>
  </div>
</div>

      {/* Meta próxima */}
      <div className="next-goal">
        <div className="goal-card">
          <span>Próxima meta</span>
          <p>Dominio de tecnologías educativas</p>
          <img src={proxMeta}  alt="Meta Medal" />
        </div>
      </div>

      {/* Búsqueda y hora */}
      <div className="header-right">
        <div className="search-container">
          <input type="text" placeholder="Buscar" />
          <FaSearch className="search-icon" />
        </div>
        <div className="datetime">
          <div className="date">Mar 03 Dic</div>
          <div className="time">2:31<span>PM</span></div>
          <div className="year">2025</div>
        </div>
      </div>
    </div>
  );
};

export default HeaderProfile;