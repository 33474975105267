import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ForgotPassword.css';

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setMessage('');

    try {
      // Aquí iría la lógica para enviar el correo de recuperación
      // Por ahora solo simulamos un delay
      await new Promise(resolve => setTimeout(resolve, 1000));
      setMessage('Se ha enviado un enlace de recuperación a tu correo electrónico');
      setTimeout(() => {
        navigate('/home');
      }, 3000);
    } catch (error) {
      setMessage('Ocurrió un error. Por favor intenta de nuevo.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="forgot-password-container">
      <aside className="forgot-password-sidebar">
        <div className="logo-container">
          <img src="/logoSEP.png" alt="Logo SEP" className="logo-sep" />
          <img src="/logoUSICAMM.png" alt="USICAMM" className="logo-usicamm" />
        </div>
      </aside>

      <main className="forgot-password-main">
        <div className="forgot-password-card">
          <h1>Recuperar Contraseña</h1>
          <p className="forgot-password-subtitle">
            Ingresa tu correo electrónico y te enviaremos las instrucciones para restablecer tu contraseña
          </p>

          {message && (
            <div className={`message ${message.includes('error') ? 'error' : 'success'}`}>
              {message}
            </div>
          )}

          <form onSubmit={handleSubmit} className="forgot-password-form">
            <div className="form-group">
              <label htmlFor="email">Correo Electrónico</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="ejemplo@correo.com"
                required
              />
            </div>

            <div className="form-actions">
              <button 
                type="submit" 
                className="btn-submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Enviando...' : 'Enviar Instrucciones'}
              </button>
              <button 
                type="button" 
                className="btn-cancel"
                onClick={() => navigate('/home')}
              >
                Cancelar
              </button>
            </div>
          </form>

          <div className="help-text">
            <p>¿Recordaste tu contraseña? <span onClick={() => navigate('/login')} className="login-link">Iniciar sesión</span></p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ForgotPassword; 