// src/components/Footer.tsx
import React from 'react';
import './Footer.css';
import logogob01 from '../../media/logogob-02.png';
import PLE from '../../media/PLE.png'; // Importa la imagen PLE
import RenderImg from '../../../renderimg'; // Importa el componente renderimg

const Footer: React.FC = () => {
    const rlogogob01 = [logogob01];
    const rPLE = [PLE]

    return (
        <footer className="footer">
            <div className="footer-container-top">
                <div className='container-footer1'>
                    <div className='image-footer1' style={{ 
                        width: '1500px',  // Aumentado el ancho
                        height: '150px',  // Aumentado el alto
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '1px'
                    }}>
                        <RenderImg 
                            images={rlogogob01} 
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                                objectFit: 'contain'
                            }}
                        />
                    </div>
                </div>
                <div className='container-footer2'>
                    <div className='texttitle-footer1'>
                        <h5 style={{ marginBottom: '3px', fontSize: '2vh' }}>
                            <span>Enlaces</span>
                        </h5>
                        <nav className='textlink-footer1'>
                            <a href="https://participa.gob.mx/" target="_blank" rel="noreferrer noopener" style={{ color: 'white', textDecoration: 'none' }}>Participa</a><br />
                            <a href="https://www.gob.mx/publicaciones" target="_blank" rel="noreferrer noopener" style={{ color: 'white', textDecoration: 'none' }}>Publicaciones Oficiales</a><br />
                            <a href="http://www.ordenjuridico.gob.mx/" target="_blank" rel="noreferrer noopener" style={{ color: 'white', textDecoration: 'none' }}>Marco Jurídico</a><br />
                            <a href="https://consultapublicamx.inai.org.mx/vut-web/" target="_blank" rel="noreferrer noopener" style={{ color: 'white', textDecoration: 'none' }}>Plataforma Nacional de Transparencia</a>
                        </nav>
                    </div>
                </div>
                <div className='container-footer3'>
                    <div className='texttitle-footer1'>
                        <h5 style={{ marginBottom: '3px', fontSize: '2vh' }}>
                            <span>¿Qué es gob.mx?</span>
                        </h5>
                        <p className='text-footer'>Es el portal único de trámites, información y participación ciudadana.</p>
                    </div>
                </div>
                <div className='container-footer4'>
                    <div className='text-footer1'>
                        <nav className='textlink-footer2'>
                            <a href="https://datos.gob.mx/" target="_blank" rel="noreferrer noopener" className="footer-link" style={{ color: 'white', textDecoration: 'none' }}>Portal de datos abiertos</a><br />
                            <a href="https://www.gob.mx/accesibilidad" target="_blank" rel="noreferrer noopener" className="footer-link" style={{ color: 'white', textDecoration: 'none' }}>Declaración de accesibilidad</a><br />
                            <a href="https://www.gob.mx/privacidadintegral" target="_blank" rel="noreferrer noopener" className="footer-link" style={{ color: 'white', textDecoration: 'none' }}>Aviso de privacidad integral</a><br />
                            <a href="https://www.gob.mx/privacidadsimplificado" target="_blank" rel="noreferrer noopener" className="footer-link" style={{ color: 'white', textDecoration: 'none' }}>Aviso de privacidad simplificado</a><br />
                            <a href="https://www.gob.mx/terminos" target="_blank" rel="noreferrer noopener" className="footer-link" style={{ color: 'white', textDecoration: 'none' }}>Términos y Condiciones</a><br />
                            <a href="https://www.gob.mx/terminos#medidas-seguridad-informacion" target="_blank" rel="noreferrer noopener" className="footer-link" style={{ color: 'white', textDecoration: 'none' }}>Política de seguridad</a><br />
                            <a href="https://www.gob.mx/sitemap" target="_blank" rel="noreferrer noopener" className="footer-link" style={{ color: 'white', textDecoration: 'none' }}>Mapa de sitio </a>
                        </nav>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;