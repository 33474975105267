import React from 'react';
import './ProgressSection.css';

interface ConvocatoriasProps {
  width?: string;
  height?: string;
  position?: 'static' | 'relative' | 'absolute' | 'fixed';
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  className?: string;
}

const Convocatorias: React.FC<ConvocatoriasProps> = ({
                                                       width,
                                                       height,
                                                       position,
                                                       top,
                                                       left,
                                                       right,
                                                       bottom,
                                                       className = ''
                                                     }) => {
  const containerStyle = {
    width,
    height,
    position,
    top,
    left,
    right,
    bottom,
  };

  return (
      <div
          className={`progress-container ${className}`}
          style={containerStyle}
      >
        {/* Encabezado principal */}
        <div className="convocatorias-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
          <h3 style={{ margin: '0', fontSize: '16px' }}>CONVOCATORIAS<br />PARA EDUCACIÓN MEDIA SUPERIOR</h3>
          <span style={{ fontWeight: 'bold', fontSize: '14px' }}>2025-2026</span>
        </div>

        {/* Sección con tres columnas */}
        <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: '5px',
            }}
        >
          <div style={{ textAlign: 'center' }}>
            <h4 style={{ margin: '5px 0', fontSize: '14px' }}>Admisión</h4>
            <ul style={{ listStyleType: 'none', padding: '0', margin: '0' }}>
              <li><a href="#" style={{ textDecoration: 'none', color: '#007bff', fontSize: '12px' }}>Proceso 1</a></li>
              <li><a href="#" style={{ textDecoration: 'none', color: '#007bff', fontSize: '12px' }}>Proceso 2</a></li>
              <li><a href="#" style={{ textDecoration: 'none', color: '#007bff', fontSize: '12px' }}>Proceso 3</a></li>
            </ul>
          </div>

          <div style={{ textAlign: 'center' }}>
            <h4 style={{ margin: '5px 0', fontSize: '14px' }}>Reconocimiento</h4>
            <ul style={{ listStyleType: 'none', padding: '0', margin: '0' }}>
              <li><a href="#" style={{ textDecoration: 'none', color: '#007bff', fontSize: '12px' }}>Reconocimiento 1</a></li>
              <li><a href="#" style={{ textDecoration: 'none', color: '#007bff', fontSize: '12px' }}>Reconocimiento 2</a></li>
              <li><a href="#" style={{ textDecoration: 'none', color: '#007bff', fontSize: '12px' }}>Reconocimiento 3</a></li>
            </ul>
          </div>

          <div style={{ textAlign: 'center' }}>
            <h4 style={{ margin: '5px 0', fontSize: '14px' }}>Promoción</h4>
            <ul style={{ listStyleType: 'none', padding: '0', margin: '0' }}>
              <li><a href="#" style={{ textDecoration: 'none', color: '#007bff', fontSize: '12px' }}>Promoción Vertical</a></li>
              <li><a href="#" style={{ textDecoration: 'none', color: '#007bff', fontSize: '12px' }}>Promoción Horizontal</a></li>
              <li><a href="#" style={{ textDecoration: 'none', color: '#007bff', fontSize: '12px' }}>Promoción Especial</a></li>
            </ul>
          </div>
        </div>
      </div>
  );
};

export default Convocatorias;
