import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Calendario from '../Internal/calendar';
import BottomRightContainer from '../Internal/BottomRightContainer';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column; /* Mantener la dirección de columna */
  overflow: hidden;
`;

const Column = styled.div<{ heightPercentage: number }>`
  height: ${(props) => props.heightPercentage}%;
  width: 100%;
  display: flex; /* Use flexbox to center content */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  overflow: hidden;
`;

type Props = {}; 

const BottomHomeRight: React.FC<Props> = () => {
  const [topHeight, setTopHeight] = useState(50);
  const [bottomHeight, setBottomHeight] = useState(50);

  const adjustHeights = () => {
    const totalHeight = window.innerHeight; // Altura total de la ventana
    const newTopHeight = Math.min(50, (totalHeight - 100) / totalHeight * 100); // Ajusta el topHeight
    const newBottomHeight = 100 - newTopHeight; // Ajusta el bottomHeight
    setTopHeight(newTopHeight);
    setBottomHeight(newBottomHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', adjustHeights); // Escucha cambios de tamaño de la ventana
    adjustHeights(); // Ajusta las alturas inicialmente

    return () => {
      window.removeEventListener('resize', adjustHeights); // Limpia el evento al desmontar
    };
  }, []);

  return (
    <Container>
      <Column heightPercentage={topHeight} className="column-top">
        <BottomRightContainer />
      </Column>
      <Column heightPercentage={bottomHeight} className="column-bottom">
        <Calendario /> 
      </Column>
    </Container>
  );
};

export default BottomHomeRight;