// src/components/Navmenu.tsx
import React from 'react';
import { Link } from 'react-router-dom'; // Asegúrate de tener react-router-dom instalado
import './Sidebar.css';
import RenderImg from '../../../renderimg'; 
import maestroImg from '../../media/gerardo.png'

const Sidebar: React.FC = () => {
    const imgMaestro = [maestroImg];
    return (
    <div className="navmenu">
    {/* Perfil */}
    <div className="profile-section">
        <span className="hero-tag">HÉROE</span>
        <div className="profile-image">
          <img src={imgMaestro[0]} alt="Profile" />
        </div>
        <span className="profile-tag">MAESTRO DE MÉXICO</span>
      </div>
        {/* Dashboard */}
        <nav className="nav-menu">
          <div className="nav-section">
          <h3>Dashboard</h3>
          </div>
  
          {/* Menú principal */}
          <div className="nav-section">
            <Link to="/resumen" className="nav-item">
              <i className="fas fa-bars"></i>
              Resumen
            </Link>
            <Link to="/convocatorias" className="nav-item">
              <i className="fas fa-file-alt"></i>
              Convocatorias
            </Link>
            <Link to="/mensajes" className="nav-item">
              <i className="fas fa-envelope"></i>
              Mensajes
            </Link>
            <Link to="/documentos" className="nav-item">
              <i className="fas fa-folder"></i>
              Documentos
            </Link>
            <Link to="/evaluacion" className="nav-item">
              <i className="fas fa-check-circle"></i>
              Evaluación
            </Link>
          </div>
  
          {/* Formación */}
          <div className="nav-section">
            <h3>Formación</h3>
            <Link to="/instituciones" className="nav-item">
              <i className="fas fa-building"></i>
              Instituciones Educativas Certificadas
            </Link>
            <Link to="/catalogo" className="nav-item">
              <i className="fas fa-book"></i>
              Catálogo de Cursos
            </Link>
            <Link to="/plan" className="nav-item">
              <i className="fas fa-graduation-cap"></i>
              Plan de Estudio
            </Link>
          </div>
  
          {/* Aplicaciones */}
          <div className="nav-section">
            <h3>Aplicaciones</h3>
            <Link to="/mm-recursos" className="nav-item">
              <i className="fas fa-cube"></i>
              Mm recursos
            </Link>
            <Link to="/streamm" className="nav-item">
              <i className="fas fa-play"></i>
              Streamm
            </Link>
            <Link to="/cardmm" className="nav-item">
              <i className="fas fa-credit-card"></i>
              Card mm
            </Link>
            <Link to="/gobi" className="nav-item">
              <i className="fas fa-robot"></i>
              Gobi
            </Link>
            <Link to="/mmatematic" className="nav-item">
              <i className="fas fa-calculator"></i>
              Mmatematic
            </Link>
            <Link to="/merito" className="nav-item">
              <i className="fas fa-award"></i>
              Mérito
            </Link>
          </div>
  
          {/* FORMMEX */}
          <div className="nav-section">
            <h3>FORMMEX</h3>
            <Link to="/acuerdos" className="nav-item">
              <i className="fas fa-handshake"></i>
              Acuerdos
            </Link>
            <Link to="/comunicados" className="nav-item">
              <i className="fas fa-bullhorn"></i>
              Comunicados
            </Link>
            <Link to="/estructura" className="nav-item">
              <i className="fas fa-sitemap"></i>
              Estructura
            </Link>
            <Link to="/procesos" className="nav-item">
              <i className="fas fa-cogs"></i>
              Procesos
            </Link>
          </div>
  
          {/* Leyes */}
          <div className="nav-section">
            <h3>Leyes</h3>
            <Link to="/ley-trabajo" className="nav-item">
              <i className="fas fa-gavel"></i>
              Ley del Trabajo
            </Link>
            <Link to="/ley-educacion" className="nav-item">
              <i className="fas fa-book-reader"></i>
              Ley General de Educación
            </Link>
            <Link to="/ley-nna" className="nav-item">
              <i className="fas fa-child"></i>
              Ley General de los NNA
            </Link>
          </div>
  
          {/* Configuración */}
          <div className="nav-section">
            <h3>Configuración</h3>
            <Link to="/modo-oscuro" className="nav-item">
              <i className="fas fa-moon"></i>
              Modo oscuro
            </Link>
          </div>
        </nav>
      </div>
    );

};

export default Sidebar;