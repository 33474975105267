const API_URL = 'https://edward-kills-dist-layers.trycloudflare.com/v1/chat/completions';

interface Message {
  role: "user" | "assistant";
  content: string;
}

interface ChatResponse {
  choices: [{
    message: {
      content: string;
    }
  }];
}

let chatHistory: Message[] = [];

export const sendMessage = async (message: string): Promise<string> => {
  try {
    // Agregar mensaje del usuario al historial
    chatHistory.push({ role: "user", content: message });

    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        mode: "chat",
        character: "usicamm",
        messages: chatHistory
      }),
    });

    if (!response.ok) {
      throw new Error('Error en la respuesta del servidor');
    }

    const data: ChatResponse = await response.json();
    const assistantMessage = data.choices[0].message.content;

    // Agregar respuesta del asistente al historial
    chatHistory.push({ role: "assistant", content: assistantMessage });

    return assistantMessage;
  } catch (error) {
    console.error('Error al enviar mensaje:', error);
    throw error;
  }
};

// Función para limpiar el historial si es necesario
export const clearChatHistory = () => {
  chatHistory = [];
}; 