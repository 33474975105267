import React from 'react';
import './ProgressSection.css';

interface FelicidadesProps {
    width?: string;
    height?: string;
    position?: 'static' | 'relative' | 'absolute' | 'fixed';
    top?: string;
    left?: string;
    right?: string;
    bottom?: string;
    className?: string;
}

const Felicidades: React.FC<FelicidadesProps> = ({
                                                     width,
                                                     height,
                                                     position,
                                                     top,
                                                     left,
                                                     right,
                                                     bottom,
                                                     className = ''
                                                 }) => {
    const containerStyle = {
        width,
        height,
        position,
        top,
        left,
        right,
        bottom,
    };

    return (
        <div
            className={`progress-container ${className}`}
            style={containerStyle}
        >
            {/* Encabezado principal */}
            <div className="felicidades-header" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '30px' }}>
                <h3 style={{ margin: '0', fontSize: '22px', textAlign: 'center' }}>Felicidades<br />Eres viable para aplicar</h3>
            </div>

            {/* Botones de acción */}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    marginTop: '30px',
                }}
            >
                <button style={{ padding: '14px 28px', fontSize: '18px', cursor: 'pointer', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '4px' }}>
                    Descarga
                </button>
                <button style={{ padding: '14px 28px', fontSize: '18px', cursor: 'pointer', backgroundColor: '#28a745', color: '#fff', border: 'none', borderRadius: '4px' }}>
                    Aplicar
                </button>
            </div>
        </div>
    );
};

export default Felicidades;
