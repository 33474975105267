import React from 'react';
import { useNavigate } from 'react-router-dom'; // Usamos useNavigate para la navegación

const Settings: React.FC = () => {
    const navigate = useNavigate(); // Usamos el hook useNavigate para navegar entre páginas

    // Opciones de configuración que el maestro puede elegir
    const settingsOptions = [
        {
            id: 1,
            title: 'Cambiar Contraseña',
            description: 'Actualiza tu contraseña para mantener la seguridad de tu cuenta.',
            route: '/change-password', // Ruta para cambiar la contraseña
        },
        {
            id: 2,
            title: 'Actualizar Información Personal',
            description: 'Modifica tu nombre, dirección o información de contacto.',
            route: '/update-profile', // Ruta para actualizar el perfil
        },
        {
            id: 3,
            title: 'Notificaciones de Ascensos',
            description: 'Activa o desactiva las notificaciones sobre ascensos disponibles en Carrera Magisterial.',
            route: '/notifications', // Ruta para configurar notificaciones
        },
        {
            id: 4,
            title: 'Preferencias de Idioma',
            description: 'Selecciona el idioma de la interfaz de usuario.',
            route: '/language-preferences', // Ruta para configurar idioma
        },
        {
            id: 5,
            title: 'Cerrar Sesión',
            description: 'Cierra sesión de tu cuenta actual.',
            route: '/login', // Ruta para cerrar sesión
        },
    ];

    // Función para regresar al inicio
    const handleGoHome = () => {
        navigate('/home'); // Redirige a la página de inicio
    };

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column' as 'column', // Aseguramos que la dirección sea 'column'
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f4f4f4',
            padding: '20px',
        },
        card: {
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            width: '100%',
            maxWidth: '800px',
        },
        title: {
            textAlign: 'center' as 'center', // Aseguramos que sea 'center'
            fontSize: '24px',
            marginBottom: '20px',
        },
        settingsList: {
            listStyleType: 'none',
            padding: '0',
        },
        settingsItem: {
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            marginBottom: '10px',
            padding: '15px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
            cursor: 'pointer',
        },
        settingsTitle: {
            fontSize: '18px',
            fontWeight: 'bold',
            color: '#333',
        },
        settingsDescription: {
            fontSize: '16px',
            color: '#666',
        },
        homeButton: {
            width: '100%',
            padding: '10px',
            backgroundColor: '#2196F3',
            color: 'white',
            fontSize: '16px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            marginTop: '20px',
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <h1 style={styles.title}>Configuración</h1>

                <ul style={styles.settingsList}>
                    {settingsOptions.map((option) => (
                        <li
                            key={option.id}
                            style={styles.settingsItem}
                            onClick={() => navigate(option.route)} // Navegamos a la ruta correspondiente
                        >
                            <div style={styles.settingsTitle}>{option.title}</div>
                            <div style={styles.settingsDescription}>{option.description}</div>
                        </li>
                    ))}
                </ul>

                <button onClick={handleGoHome} style={styles.homeButton}>Regresar al Inicio</button>
            </div>
        </div>
    );
};

export default Settings;
