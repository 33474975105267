import React from 'react';
import { useNavigate } from 'react-router-dom'; // Usamos useNavigate para la navegación

const Promotion: React.FC = () => {
    const navigate = useNavigate(); // Usamos el hook useNavigate para navegar entre páginas

    // Tipos de promoción de acuerdo a la USICAMM
    const promotionTypes = [
        {
            id: 1,
            level: 'Nivel 1',
            description: 'Ingreso a la Carrera Magisterial. Requiere contar con la formación básica y experiencia mínima como docente.',
        },
        {
            id: 2,
            level: 'Nivel 2',
            description: 'Requiere una evaluación del desempeño y cumplir con los requisitos establecidos, como la formación continua y el tiempo de servicio.',
        },
        {
            id: 3,
            level: 'Nivel 3',
            description: 'Requiere una evaluación del desempeño, logros educativos y formación avanzada. Este nivel está dirigido a docentes con experiencia significativa.',
        },
        {
            id: 4,
            level: 'Nivel 4',
            description: 'Promoción a un nivel más alto, basado en la investigación educativa y el liderazgo en el aula. Es necesario contar con una formación académica avanzada.',
        },
        {
            id: 5,
            level: 'Nivel 5',
            description: 'Promoción final, requiere una evaluación integral de la carrera magisterial, destacando logros extraordinarios en la docencia y otros aspectos de desarrollo profesional.',
        },
    ];

    // Función para regresar al inicio
    const handleGoHome = () => {
        navigate('/home'); // Redirige a la página de inicio
    };

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column' as 'column', // Aseguramos que la dirección sea 'column'
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f4f4f4',
            padding: '20px',
        },
        card: {
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            width: '100%',
            maxWidth: '800px',
        },
        title: {
            textAlign: 'center' as 'center', // Aseguramos que sea 'center'
            fontSize: '24px',
            marginBottom: '20px',
        },
        promotionList: {
            listStyleType: 'none',
            padding: '0',
        },
        promotionItem: {
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            marginBottom: '10px',
            padding: '15px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        },
        levelTitle: {
            fontSize: '20px',
            fontWeight: 'bold',
            color: '#333',
        },
        levelDescription: {
            fontSize: '16px',
            color: '#666',
        },
        homeButton: {
            width: '100%',
            padding: '10px',
            backgroundColor: '#2196F3',
            color: 'white',
            fontSize: '16px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            marginTop: '20px',
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <h1 style={styles.title}>Tipos de Promoción en Carrera Magisterial</h1>

                <ul style={styles.promotionList}>
                    {promotionTypes.map((promotion) => (
                        <li key={promotion.id} style={styles.promotionItem}>
                            <div style={styles.levelTitle}>{promotion.level}</div>
                            <div style={styles.levelDescription}>{promotion.description}</div>
                        </li>
                    ))}
                </ul>

                <button onClick={handleGoHome} style={styles.homeButton}>Regresar al Inicio</button>
            </div>
        </div>
    );
};

export default Promotion;
