// src/components/Home.tsx
import React from 'react';
import './home.styles.css';
//import ChatbotLazaro01 from './ChatbotLazaro01';
//import ContainerBottomLeftCenter from './utils/ContainerBottomLefCenter';
//import Calendar from './utils/Calendar';
//import BottomRightContainer from './utils/BottomRightContainer';
//import BottomCenter from './utils/BottomCenter';
//import ContainerLeftTop from './utils/ContainerBottomLefTop';
//import Accordion01 from './utils/ContainerBottomLefBottom';
import BottomHomeLeft from './pages/Internal/BottomHomeLeft';
import BottomHomeCenter from './pages/Internal/BottomHomeCenter';
import BottomHomeRight from './pages/Internal/BottomHomeRight';

const Home: React.FC = () => {
    return (
        <div className="home">
            <div className="container-bottom">
                <div className="container-left">
                    <BottomHomeLeft />
                </div>
                <div className="container-center">
                    <div className="containerh-bottom-center-top">
                        <BottomHomeCenter/>
                    </div>

                </div>
                <div className="container-right">
                    <div className="containerh-bottom-right">
                        <BottomHomeRight/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;