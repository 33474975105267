import React, { useState, useEffect } from 'react';
import './Header.css';
import banner02 from '../../media/banners_gob-02.png';
import banner04 from '../../media/banners_gob-04.png';
import banner05 from '../../media/banners_gob-05.png';
import banner06 from '../../media/banners_gob-06.png';

const Header: React.FC = () => {
  const [currentBanner, setCurrentBanner] = useState(0);
  
  const banners = [
    banner02,
    banner04,
    banner05,
    banner06
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBanner((prev) => (prev + 1) % banners.length);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="header">
      <div className="container-top">
        <div className="banner-container">
          <img 
            src={banners[currentBanner]} 
            alt={`Banner ${currentBanner + 1}`} 
            className="banner-image"
          />
        </div>
      </div>
    </div>
  );
};

export default Header;