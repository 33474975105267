import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const Login: React.FC = () => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const navigate = useNavigate(); // Para navegar entre las páginas

    const [curp, setCurp] = useState("");
    const [password, setPassword] = useState("");

    const handleFaceRecognitionLogin = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
            }

            setTimeout(async () => {
                const captureFrame = () => {
                    const canvas = document.createElement("canvas");
                    canvas.width = videoRef.current!.videoWidth;
                    canvas.height = videoRef.current!.videoHeight;
                    const context = canvas.getContext("2d");
                    context?.drawImage(videoRef.current!, 0, 0, canvas.width, canvas.height);

                    return canvas.toDataURL("image/jpeg");
                };

                const frameData = captureFrame();

                if (videoRef.current?.srcObject) {
                    const mediaStream = videoRef.current.srcObject as MediaStream;
                    mediaStream.getTracks().forEach((track) => track.stop());
                    videoRef.current.srcObject = null;
                }

                navigate("/home");

                try {
                    const response = await fetch("http://localhost:5000/recognize-face", {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ image: frameData }),
                    });

                    if (!response.ok) {
                        throw new Error("Error en la respuesta del servidor");
                    }

                    const result = await response.json();
                    console.log("Resultado del backend:", result);

                    if (result.success) {
                        alert(`Acceso concedido: ${result.name}`);
                    } else {
                        alert("Acceso denegado. Usuario no reconocido.");
                    }
                } catch (error) {
                    console.error("Error al enviar la solicitud:", error);
                    alert("Hubo un problema al procesar la solicitud. Intenta de nuevo.");
                }
            }, 2000);
        } catch (error) {
            console.error("Error al acceder a la cámara:", error);
            alert("Hubo un problema al acceder a la cámara.");
        }
    };

    const handleLogin = () => {
        try {
            // Obtener usuarios registrados
            const users = JSON.parse(localStorage.getItem('users') || '[]');
            
            // Validar que los campos no estén vacíos
            if (!curp || !password) {
                alert('Por favor, complete todos los campos');
                return;
            }
    
            // Buscar usuario que coincida con CURP y contraseña
            const user = users.find((u: any) => u.curp === curp && u.password === password);
    
            if (user) {
                // Guardar usuario actual en sesión
                localStorage.setItem('currentUser', JSON.stringify(user));
                
                // Limpiar campos del formulario
                setCurp('');
                setPassword('');
                
                // Mostrar mensaje de bienvenida
                alert(`¡Bienvenido(a) ${user.nombre} ${user.apellidos}!`);
                
                // Redireccionar al home
                navigate('/home');
            } else {
                // Mensaje de error si las credenciales son incorrectas
                alert('CURP o contraseña incorrectos. Por favor, verifique sus datos.');
                
                // Limpiar solo el campo de contraseña
                setPassword('');
            }
        } catch (error) {
            console.error('Error al iniciar sesión:', error);
            alert('Ocurrió un error al intentar iniciar sesión. Por favor, intente nuevamente.');
        }
    };

    const handleFingerprintLogin = () => {
        alert("Iniciar sesión con huella dactilar.");
    };

    const handleRegister = () => {
        alert("Ir a la página de registro.");
    };

    const handleForgotPassword = () => {
        alert("Ir a la página de recuperación de contraseña.");
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.title}>USICAMM</h1>
            <form style={styles.form} onSubmit={(e) => e.preventDefault()}>
                <input
                    type="text"
                    placeholder="Ingrese su CURP"
                    value={curp}
                    onChange={(e) => setCurp(e.target.value)}
                    style={styles.input}
                />
                <input
                    type="password"
                    placeholder="Ingrese su Contraseña"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    style={styles.input}
                />
                <button type="button" onClick={handleLogin} style={styles.loginButton}>
                    Iniciar Sesión
                </button>
                <button type="button" onClick={handleFingerprintLogin} style={styles.button}>
                    🛡️ Iniciar con Huella
                </button>
                <button type="button" onClick={handleFaceRecognitionLogin} style={styles.button}>
                    📸 Iniciar con Reconocimiento Facial
                </button>
                <button type="button" onClick={handleRegister} style={styles.button}>
                    Registrarse
                </button>
                <p style={styles.forgotPassword} onClick={handleForgotPassword}>
                    ¿Olvidaste tu contraseña?
                </p>
            </form>
            <video ref={videoRef} autoPlay style={styles.video} />
        </div>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#f5f5f5",
    },
    title: {
        fontSize: "2rem",
        marginBottom: "20px",
    },
    form: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "300px",
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
    },
    input: {
        width: "100%",
        padding: "10px",
        margin: "10px 0",
        borderRadius: "5px",
        border: "1px solid #ccc",
        fontSize: "1rem",
    },
    button: {
        width: "100%",
        padding: "10px",
        margin: "10px 0",
        backgroundColor: "#007bff",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        fontSize: "1rem",
        cursor: "pointer",
    },
    loginButton: {
        width: "100%",
        padding: "10px",
        margin: "10px 0",
        backgroundColor: "#28a745",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        fontSize: "1rem",
        cursor: "pointer",
    },
    forgotPassword: {
        color: "#007bff",
        cursor: "pointer",
        marginTop: "10px",
        textDecoration: "underline",
    },
    video: {
        width: "320px",
        height: "240px",
        marginTop: "20px",
        border: "1px solid #ccc",
        borderRadius: "5px",
    },
};

export default Login;
